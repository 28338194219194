import React from 'react';
import './HamburgerButton.scss';

const HamburgerButton = ({
  color,
  className = '',
  isMenuOpen = false,
  toggleMenu = () => {}
}) => (
  <button
    type="button"
    className={`hamburger-button ${className} ${isMenuOpen ? 'close' : ''}`}
    onClick={toggleMenu}
    title="Menu"
  >
    <span className="line" style={{ backgroundColor: color }} />
    <span className="line" style={{ backgroundColor: color }} />
    <span className="line" style={{ backgroundColor: color }} />
  </button>
);


export default React.memo(HamburgerButton);
