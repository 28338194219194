import React, { useState } from 'react';
import { Link } from 'gatsby';
import styles from './header.module.scss';
import Logo from '../logo';
import { GithubIcon, StackOverflowIcon, MailIcon } from '../icons';
import HamburgerButton from '../HamburgerButton';

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <header className={styles.page_header}>
      <div className={styles.content}>
        <div className={styles.left_content}>
          <Link to="/">
            <Logo />
            <span className={styles.name}>
              <strong>CHRIS</strong> SANDVIK
            </span>
          </Link>
          <HamburgerButton
            className={styles.hamburger_button}
            isMenuOpen={isMenuOpen}
            toggleMenu={() => setMenuOpen(!isMenuOpen)}
           />
        </div>

        <div
          className={`${styles.right_content} ${
            isMenuOpen ? styles.open : styles.closed
          }`}
        >
          <div className={styles.header_link_section}>
            {/* <Link className={styles.header_link} to="/projects">
              Projects
            </Link> */}
            <Link className={styles.header_link} to="/posts">
              Posts
            </Link>
          </div>
          <div className={styles.icon_container}>
            <a
              href="https://github.com/csandman"
              className={styles.link_icon}
              target="_blank"
              rel="noopener noreferrer"
              alt="Github"
            >
              <GithubIcon style={{ height: 22, width: 22 }} />
            </a>
            <a
              href="https://stackoverflow.com/users/11020515/chris-sandvik"
              className={styles.link_icon}
              target="_blank"
              rel="noopener noreferrer"
              alt="Stack Overflow"
            >
              <StackOverflowIcon style={{ height: 22, width: 22 }} />
            </a>
            <a
              href="mailto:chris.sandvik@gmail.com"
              className={styles.link_icon}
              target="_blank"
              rel="noopener noreferrer"
              alt="Email"
            >
              <MailIcon size={24} />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
