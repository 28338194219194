import React from 'react';
import styles from './footer.module.scss';

function Footer() {
  return (
    <footer className={styles.main_footer}>
      <div className={styles.content}>
        <span>{`© ${new Date().getFullYear()} | csandvik.com`}</span>
      </div>
    </footer>
  );
}

export default Footer;
